/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Logo from "../images/CB_logo.png"
// const GetDetails = (name) => {
//   navigate(`/details/${name}`)
//   return "Success"
// }
export default function SearchFormPage(data) {
  return (
    <div>
      <Header />
      <section className="container-fluid horizontal-ads-section">
        <div className="ads d-flex flex-wrap align-items-center justify-content-center">
          <img
            src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="Ad"
          />
          <img
            src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="Ad"
          />
          <img
            src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="Ad"
          />
          <img
            src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="Ad"
          />
          <img
            src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="Ad"
          />
        </div>
      </section>
      <section className="container main-category">
        <div className="row">
          <div className="col-md-10">
            {/* {result.each((item) => { */}
            {/* return (  */}
            <div className="category-card">
              <span className="website-logo">
                <img
                  src={Logo}
                  style={{ maxWidth: `100%` }}
                  height="auto"
                  alt="christianbluelogo"
                />
              </span>
              <div className="row company-details d-flex flex-wrap align-items-center justify-content-start">
                <div className="col-md-2 company-logo">
                  {/* <img src={item.company_logo} style={{borderRadius: `50%`}} height="100" width="100" alt="company_logo"/> */}
                </div>
                <div className="col-md-12 company-about">
                  <p className="company-name">{/* {item.name} */}</p>
                  <p
                    style={{ width: `85% !important` }}
                    className="company-address">
                    {/* {item.address} */}
                  </p>
                </div>
              </div>
              <div className="company-details row">
                <div className="company-images col-md-6">
                  <div className="row">
                    {/* {item.images.map(({ path }) => (
                                <span className="col-md-6">
                                <img
                                    style={{
                                    width: `100%`,
                                    height: `200px`,
                                    marginTop: `35px`,
                                    }}
                                    src={path}
                                    alt="N/A"
                                />
                                </span>
                            ))} */}
                  </div>
                </div>
                <div className="company-desc col-md-6">
                  <p>{/* {item.description} */}</p>
                  <div className="tag">
                    <p>Residential - Commercial - Experienced Plumbers</p>
                  </div>
                  <p className="reviews">
                    <a>Reviews</a>
                  </p>
                  <button navigate type="button" className="btn btn-details">
                    view details
                  </button>
                </div>
              </div>
              <div className="company-footer">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <ul className="services">
                      <li>
                        {/* {item.service_frequency
                                    ? item.service_frequency
                                    : "N/A"} */}
                      </li>
                      <li>{/* {item.days_open ? item.days_open : "N/A"} */}</li>
                    </ul>
                  </div>
                  <div className="col-md-6 footer-links">
                    <span>
                      <a href="#">Website</a>
                    </span>
                    <span>|</span>
                    <span>
                      <a href="#">Coupon</a>
                    </span>
                    <span>|</span>
                    <span>
                      <a href="#">More Info</a>
                    </span>
                    <span>|</span>
                    <span>
                      <a href="#">View Ad</a>
                    </span>
                    <span>|</span>
                    <span>
                      <a href="#">Watch</a>
                    </span>
                    <span>|</span>
                    <span>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* )
                })} */}
          </div>
          {/* <div className="col-lg-3">
            <aside>
              <div className="search">
                <input type="search" name="" placeholder="Search" />
              </div>
              <p className="aside-head">Christian Business Spotlights</p>
              <div className="aside-card">
                <p className="aside-card-head">Ladd &amp; Carter Tax Service</p>
                <p className="aside-card-address">
                  1226 Linden Ave Dayton, OH 45410
                </p>
                <img
                  src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1350&amp;q=80"
                  alt="Ad"
                />
                <p className="aside-card-desc">
                  Ladd &amp; Carter Tax Service provides year-round affordable
                  full service Tax Preparation for individuals &amp; businesses
                  (preparing Federal, State, and City Tax returns), plus
                  bookkeeping for small businesses.
                </p>
                <div className="aside-card-read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
              <div className="aside-card">
                <p className="aside-card-head">Ladd &amp; Carter Tax Service</p>
                <p className="aside-card-address">
                  1226 Linden Ave Dayton, OH 45410
                </p>
                <img
                  src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1350&amp;q=80"
                  alt="Ad"
                />
                <p className="aside-card-desc">
                  Ladd &amp; Carter Tax Service provides year-round affordable
                  full service Tax Preparation for individuals &amp; businesses
                  (preparing Federal, State, and City Tax returns), plus
                  bookkeeping for small businesses.
                </p>
                <div className="aside-card-read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </aside>
          </div> */}
        </div>
      </section>
      <Footer />
    </div>
  )
}
